import {
  GET_PROJECTS,
  GET_LANG,
  GET_CURRENCY,
  GET_COMPANIES,
  GET_MONEYTYPE,
  GET_STATICPAGES,
  GET_STATICPAGE,
  GET_FILTEREDSTATE,
  GET_MENUS,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };

    case GET_FILTEREDSTATE:
      return {
        ...state,
        filteredState: action.payload,
      };
    case GET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case GET_STATICPAGES:
      return {
        ...state,
        staticPages: action.payload,
      };
      case GET_STATICPAGE:
        return {
          ...state,
          staticPage: action.payload,
        };
    case GET_MENUS:
      return {
        ...state,
        menus: action.payload,
      };
    case GET_MONEYTYPE:
      return {
        ...state,
        money: action.payload,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };

    default:
      return state;
  }
};
