import React, { Fragment, useEffect, useContext } from 'react';
import Footer from '../components/Footer';
import DataContext from '../context/dataContext';
import ReactFullpage from '@fullpage/react-fullpage';
import RegisterForm from '../components/RegisterForm';
import PromoCompanies from '../components/PromoCompanies';
import { InView } from 'react-intersection-observer';
import _ from 'lodash';

const Companies = ({ location }) => {
  const dataContext = useContext(DataContext);
  const { getCompanies, companies, filteredState } = dataContext;

  useEffect(() => {
    getCompanies(location, filteredState);
    // getFilteredCompanies();
    // eslint-disable-next-line
  }, [filteredState]);
  var sortedCompanies;
  if (companies) {    
    sortedCompanies = _.orderBy(companies, ['order'], ['asc']);
  }
  return (
    <Fragment>
      <ReactFullpage
        //fullpage options
        licenseKey={'E2D9F03D-718B42A6-8BAC5EBE-085C8480'}
        navigation={true}
        navigationPosition='right'
        // lockAnchors={true}
        verticalCentered={false}
        responsiveWidth={0}
        scrollingSpeed={700} /* Options here */
        lazyLoading={true}
        render={({ state, fullpageApi }) => {
          return (
            <InView>
              <div className='promo-slider'>
                {sortedCompanies.map((projectItem) => (
                  <div className='section fp-auto-height' key={projectItem.id}>
                    <PromoCompanies
                      fullpageApi={fullpageApi}
                      name={projectItem.name}
                      sector={projectItem.sector}
                      slug={projectItem.slug}
                      cover={projectItem.cover && projectItem.cover.url}
                      link={projectItem.link}
                      logo={projectItem.logo && projectItem.logo}
                      stream_id={projectItem.stream_id}
                      spot={projectItem.spot}
                    />
                  </div>
                ))}
                <div className='section fp-auto-height' id='section-footer'>
                  <RegisterForm />
                  <Footer />
                </div>
              </div>
            </InView>
          );
        }}
      />
      {/* <DesktopGetInTouchBotton /> */}
      {/* <FixedGetInTouchButton /> */}
    </Fragment>
  );
};

export default Companies;
