import React from 'react';
import { InView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { handleScroll } from '../animations/animation';
const Promo = ({
  video,
  src,
  detailClass,
  fullpageApi,
  title,
  sub_title,
  path,
  slug,
  hasButton,
  buttonTitle,
  stream_id,
}) => {
  const handleClick = () => {
    fullpageApi
      ? fullpageApi.moveSectionDown()
      : scroller.scrollTo('myScrollToElement', {
          duration: 1000,
          delay: 50,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
  };

  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <section
        className={`promo ${detailClass ? detailClass : null}`}
        style={{
          backgroundImage: `url(${
            src ? process.env.REACT_APP_API_URL + src : ''
          })`,
        }}
      >
        <div className='promo-slide-down'>
          <button
            type='button'
            className='promo-slide-down-button'
            onClick={handleClick}
          >
            <svg className='icon icon-angle-right'>
              <path
                d='M6.39835924,6.83847283 L11.8347912,1.30286072 C12.0550696,1.08199059 12.0550696,0.724872086 11.8347912,0.504001956 L11.5020624,0.17033341 C11.281784,-0.050490829 10.9255765,-0.050490829 10.7053439,0.17033341 L6,4.97289349 L1.29465609,0.165652597 C1.07442347,-0.0552175324 0.718215988,-0.0552175324 0.497937608,0.165652597 L0.165208785,0.499275253 C-0.055069595,0.720145382 -0.055069595,1.07730977 0.165208785,1.29813401 L5.60164076,6.83379202 C5.82191914,7.05461626 6.17808086,7.05461626 6.39835924,6.83847283 Z'
                id='angle-down'
                fill='#fff'
                fillRule='nonzero'
              ></path>
            </svg>
          </button>
        </div>
        <div className='promo-content'>
          <h6 className='promo-sub-title'>
            {sub_title && sub_title.toUpperCase()}
          </h6>
          <h2 className='promo-title'>
            {title && title.toUpperCase()}
          </h2>
          {hasButton === true && (
            <NavLink
              className='btn btn-outline-light promo-action-button'
              state={slug}
              to={{ pathname: path, state: slug }}
            >
              {buttonTitle.toUpperCase()}
            </NavLink>
          )}
        </div>
        {video && (
          <video
            data-keepplaying
            autoPlay
            muted
            loop
            playsInline
            preload='yes'
            className='promo-video'
          >
            <source
              src={process.env.REACT_APP_STREAM_URL + stream_id}
              type='video/mp4'
            />
          </video>
        )}
      </section>
    </InView>
  );
};

export default Promo;
