import React, { useState, useContext, useEffect } from 'react';
import { languageOptions, moneyOptions } from '../filteroptions/options';
import { useTranslation } from 'react-i18next';
import DataContext from '../context/dataContext';
import { fixedGetInTouchAnimate } from '../animations/animation';
import { InView } from 'react-intersection-observer';
import { NavLink, useHistory } from 'react-router-dom';
import Select from 'react-select';

const Footer = () => {
  // Page History
  let history = useHistory();
  // Check Language
  let lang = localStorage.getItem('i18nextLng');
  const dataContext = useContext(DataContext);
  const { getLang, getMoneyType } = dataContext;
  useEffect(() => {
    getLang(lang);
    // eslint-disable-next-line
  }, [lang]);
  // React i18next
  const { t, i18n } = useTranslation();

  //Money State
  const [selectedMoney, setSelectedMoney] = useState(moneyOptions[0].label);
  //Language State
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].label
  );
  const handleChangeMoney = (selectedMoney) => {
    setSelectedMoney(selectedMoney);
    getMoneyType(selectedMoney.value);
  };

  const handleChangeLanguage = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
    // Change Current Language
    i18n.changeLanguage(selectedLanguage.value);
    if (history.location.pathname === '/') {
      window.location.reload();
    } else {
      history.push('/');
      window.location.reload();
    }
  };
  return (
    <InView
      onChange={(inView, entry) => {
        fixedGetInTouchAnimate(inView);
      }}
    >
      <footer id='footer'>
        <div className='container-fluid'>
          <a href='/' className='footer-logo'>
            <img src="/assets/img/xplor-logo.png" alt=""/>
          </a>
          <p className='designed-by'>Developed and designed by Brand Capital © 2020</p>
          <nav className='footer-navigation'>
            <NavLink to='#'>{t('MEDIA')}</NavLink>
            <NavLink to='#'>{t('CONTACT_US')}</NavLink>
            <NavLink to='#'>
              {t('TERMS_AND_CONDITIONS')}
            </NavLink>
            <p className='designed-by-mobile'>Developed and designed by Brand Capital © 2020</p>
            {/* <Select
              value={selectedLanguage}
              onChange={handleChangeLanguage}
              options={languageOptions}
              classNamePrefix='filterfooter'
              placeholder={selectedLanguage}
              menuPosition='absolute'
              menuPlacement='top'
            />

            <Select
              onChange={handleChangeMoney}
              options={moneyOptions}
              classNamePrefix='filterfooter'
              placeholder={selectedMoney}
              value={selectedMoney}
              menuPlacement='auto'
            /> */}
          </nav>
        </div>
      </footer>
    </InView>
  );
};

export default Footer;
